<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Mapear</p>
        <div class="container">
            <h2 class="page_header_title">Mapear</h2>
        </div>
    </div>
</div>
<div class="map">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/map1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="page_subtitle mb-4 text-white"><strong>Organizar</strong></p>

                <p class="page_text text-white">
                    A segunda-feira do Gamerllil é uma série de conversas estruturadas para construir uma base – e um foco – para a semana. A estrutura permite que a equipe compartilhe o máximo de informações o mais rápido possível, evitando as habituais conversas sinuosas.</p>
                <img src="assets/images/mapline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Mapear
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
              Segunda-feira
            </span>
            <br>
            <span>
               <strong>Livro:</strong>
               Página 68
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                Na segunda-feira, vocês iniciarão o <strong [routerLink]="'/explicar'">Gamerllil</strong> compartilhando conhecimento, entendendo o problema e escolhendo um <strong [routerLink]="'/alvo'">alvo</strong> para os esforços da semana. Com apenas cinco dias, pode parecer loucura passar um dia inteiro falando e escrevendo em quadros brancos. Mas se vocês não desacelerarem, compartilharem o que sabe e priorizarem, podem acabar desperdiçando tempo e esforço na parte errada do problema.
                <br>
                As discussões estruturadas de segunda-feira criam um caminho para a semana do Gamerllil. De parte manhã, vocês começarão pelo final, definindo um <strong [routerLink]="'/objetivo'">objetivo de longo prazo</strong> e quais <strong [routerLink]="'/perguntas'">perguntas</strong> querem responder ao final da semana de trabalho. Em seguida, vocês traçarão um <strong [routerLink]="'/mapear'">mapa</strong> do desafio. Já na parte da tarde, vocês pedirão aos <strong [routerLink]="'/especialista'">especialistas da empresa</strong> ou especialistas externos que compartilhem o que sabem. Finalmente, vocês escolherão um <strong [routerLink]="'/especialista'">alvo</strong>: uma parte ambiciosa, mas gerenciável do problema, que vocês podem resolver em apenas uma semana.
            </p>
            <br>



            <p class="page_subtitle mb-4 "><strong>O DIA A DIA DO Gamerllil</strong></p>
            <br>
            <p>
                Na segunda-feira, vocês irão traçar um <strong [routerLink]="'/mapa'">mapa do problema</strong> e
                escolherão um <strong [routerLink]="'/alvo'">alvo</strong>. Na <strong [routerLink]="'/esbocar'">terça-feira</strong>,
                vocês irão <strong [routerLink]="'/esbocar'">esboçar soluções</strong>. Na <strong
                    [routerLink]="'/decidir'">quarta-feira</strong>, vocês irão <strong [routerLink]="'/decidir'">decidir</strong>
                quais esboços são mais fortes e farão um <strong [routerLink]="'/storyboard'">storyboard</strong>. Na
                <strong [routerLink]="'/prototipo'">quinta-feira</strong>, vocês construirão um <strong
                    [routerLink]="'/prototipo'">protótipo</strong> realista. E na <strong [routerLink]="'/testar'">sexta-feira</strong>,
                vocês irão <strong [routerLink]="'/testar'">testar</strong> esse protótipo com cinco clientes-alvo.
            </p>
            <img src="assets/images/map2.png" alt="">
            <br>
            <br>
            <p>
                Claro, que o <strong [routerLink]="'/livro'">Livro Sprint</strong> do <strong
                    [routerLink]="'/jakeknapp'">Jake Knapp</strong> e o nosso <strong [routerLink]="'/curso'">Curso
                Online</strong> são guias muito mais completos para as atividades da etapa Organizar e o resto da semana
                do <strong [routerLink]="'/explicar'">Gamerllil</strong>. Mas, incluímos um checklist completo da
                etapa Organizar e algumas dicas para vocês logo abaixo. Também temos um vídeo onde explicamos o que
                acontece na etapa Organizar e mostramos alguns exemplos práticos.
                <br>
                Se mesmo assim vocês ainda tiverem dúvidas, confiram a página de <strong [routerLink]="'/faq'">Perguntas
                Frequentes</strong>. E, se não encontrarem as respostas que precisam, sintam-se à vontade para nos
                enviar uma mensagem através da nossa página de <strong [routerLink]="'/contato'">contato</strong>. Será
                sempre um prazer poder ajudá-los.
                <br>
                E, finalmente, aqui está o checklist da etapa Organizar que prometemos. Vem com a gente!
            </p>
            <br>
            <br>
            <p class="page_subtitle mb-4 "><strong>CHECKLIST</strong></p>
            <span><strong>Lembrete 1:</strong> Os horários são aproximados. Não se preocupem se vocês precisarem adiantar um pouco, atrasar um pouco ou se precisarem fazer alguns ajustes ao longo das atividades.</span>
            <br>
            <span><strong>Lembrete 2:</strong> Façam um intervalo para o almoço das 13h00 às 14h00 todos os dias. Comam juntos se for possível (é divertido). Escolham pratos leves para conservar a energia à tarde. Haverá lanchinhos se ficarem com fome depois.</span>
            <br>
            <span><strong>Lembrete 3:</strong> Façam pausas a cada sessenta ou a cada noventa minutos. Por volta das 11h00 e 15h45 todos os dias.</span>
            <br>
            <br>
            <div class="faq-group">
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>Quebrar o gelo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p>⌼ 10:00h     ⌻ 30min     ⌺ Todos     ⌹ -</p>

                        <span>Comessem o Gamerllil apresentando o facilitador e o definidor e descreva o papel de cada membro da equipe. Se nem todos se conhecem, é interessante usar alguma dinâmica para quebrar o gelo. </span>
                        <br>

                        <a [routerLink]="'/quebragelo'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>Explicar o processo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p>⌼ 10:15h     ⌻ 15min     ⌺ Facilitador     ⌹ -</p>

                        <span>Expliquem o processo de cinco dias do Gamerllil, confirmem que os participantes estarão presentes durante todo esse período, compartilhem a agenda com a programação da semana e descrevam algumas regras básicas.</span>
                        <br>

                        <a [routerLink]="'/explicar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>Definir o objetivo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p>⌼ 10:30h     ⌻ 30min     ⌺ Todos     ⌹ 73</p>

                        <span>Sejam otimistas e perguntem-se: por que estamos conduzindo esse projeto? Onde queremos estar em seis meses, um ano ou em até cinco anos? Escrevam o objetivo de longo prazo em um quadro branco.</span>
                        <br>

                        <a [routerLink]="'/objetivo'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>Listar as perguntas</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p>⌼ 11:15h     ⌻ 30min     ⌺ Todos     ⌹ 75</p>

                        <span>Sejam pessimistas e perguntem-se:  E se falharmos? Transformem esses temores em questões que poderão ser respondidas até o fim da semana. Listem as perguntas em um quadro branco.</span>
                        <br>

                        <a [routerLink]="'/perguntas'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>Traçar um mapa</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p>⌼ 11:45h     ⌻ 75min     ⌺ Todos     ⌹ 86</p>

                        <span>Façam uma lista dos clientes e atores importantes à esquerda. Desenhem o fim, com o objetivo concluído à direita. Em seguida, façam um fluxograma entre uma parte e outra mostrando como os clientes interagem com o produto ou serviço. Não compliquem: o fluxograma deve ter entre cinco e quinze etapas.</span>
                        <br>

                        <a [routerLink]="'/mapa'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>Entrevistar especialista</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p>⌼ 14:00h     ⌻ 105min     ⌺ Todos     ⌹ 95</p>

                        <span>Entrevistem especialistas da equipe e convidados de fora. Tentem fazer com que cada entrevista dure de quinze a trinta minutos. Perguntem sobre a ideia, a pesquisa de satisfação do cliente, como as coisas funcionam e projetos anteriores.</span>
                        <br>

                        <a [routerLink]="'/especialista'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>Organizar as notas como poderíamos</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p>⌼ 16:00h     ⌻ 15min     ⌺ Todos     ⌹ 103</p>

                        <span>Colem todas as notas em qualquer ordem na parede. Movam as ideias semelhantes de forma que fiquem lado a lado. Rotulem temas à medida que surgirem. Não sejam perfeccionista. Parem após cerca de quinze minutos.</span>
                        <br>

                        <a [routerLink]="'/organizarcp'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>Votar nas notas como poderíamos</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <p>⌼ 16:15h     ⌻ 15min     ⌺ Todos     ⌹ 104</p>

                        <span>Cada pessoa tem dois votos e podem votar nas próprias notas, ou até na mesma nota duas vezes. Ao final, coloquem as vencedoras no mapa.</span>
                        <br>

                        <a [routerLink]="'/votecp'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-9')" href="javascript:void(0)">
                        <span>Escolher um alvo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-9" class="collapse">
                        <p>⌼ 16:30h     ⌻ 30min     ⌺ Todos     ⌹ 112</p>

                        <span>Circulem o cliente mais importante e um momento-alvo no mapa. A equipe pode dar opiniões, mas é o Definidor que tomará a decisão final.</span>
                        <br>

                        <a [routerLink]="'/target'">Saiba Mais --></a>
                    </div>
                </div>



            </div>
            <br>


            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE Gamerllil. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn" [routerLink]="'/materiais'">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/quebragelo'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

