<ng-container *ngIf="false">
    <div class="login_bg">
        <div class="container container_login">
            <div class="row no-gutters align-items-center" style="min-height: 100vh">
                <div class="col d-none d-lg-block">

                </div>
                <div class="col-lg-3 login_col">
                    <form autocomplete="off">

                        <p class="login_lbl text-center mb-4">Faça seu login</p>
                        <!--<div class="position-relative">
                            <div class="input-material">
                                <input type="text" class="form-control" id="document" autocomplete="off"
                                       inputmode="numeric"
                                       [(ngModel)]="authInput.CpfCnpj"
                                       [dropSpecialCharacters]="false"
                                       mask="00000000000000" name="document" maxlength="100"
                                       required/>
                                <a class="right" (click)="showModal('isLogin')">
                                    <svg-icon [svgStyle]="{'stroke':'#242424'}" src="assets/svg/info.svg"></svg-icon>
                                </a>
                                <label for="document">Login</label>

                            </div>

                        </div>
                        <div class="input-material mb-0">
                            <input [type]="isEyeBool?'text':'password'" [(ngModel)]="authInput.password"
                                   (keyup.enter)="onLogin()"
                                   class="form-control" id="password"
                                   autocomplete="off"
                                   maxlength="50" name="password" required/>
                            <svg-icon (click)="isEyeBool=!isEyeBool" class="right"
                                      src="assets/svg/eye-password.svg"></svg-icon>
                            <label for="password">Senha</label>
                        </div>-->
                        <div class="text-right mb-3">
                            <a href="javascript:void(0);" class="login_text" (click)="handlerModalForgot()">
                                Esqueceu a senha?
                            </a>
                        </div>
                        <a href="javascript:void(0);" class="btn btn-block btn-primary text-white mb-4"
                           (click)="onLogin()">
                            Entrar
                        </a>
                        <p class="login_text login_text-legend">
                            Caso tenha dúvidas para se cadastrar ou
                            acessar seu login, <a href="javascript:void('');" (click)="showModal('isLogin')"><strong>clique
                            aqui</strong></a>
                            ou envie uma
                            mensagem para <a
                                href="mailto:sac@designsprint.com.br"><strong>sac@designsprint.com.br</strong></a>.
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <app-doubts></app-doubts>
</ng-container>

<!-- Modal Login -->
<div class="modal fade" id="modalLogin" tabindex="-1" role="dialog" aria-labelledby="modalLoginLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" style="max-width: 454px" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <div class="modal-title modal_title">Login</div>
                <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body">
                <p class="modal_lbl modal_lbl-lg text-center">Faça seu login ou <a href="#">cadastre-se!</a></p>
                <form autocomplete="off">
                    <div class="input-material">
                        <input type="text" class="form-control" id="document" autocomplete="off"
                               [(ngModel)]="authInput.email"
                               name="document" maxlength="100"
                               required/>
                        <a class="right" (click)="showModal('isLogin')">
                            <svg-icon [svgStyle]="{'stroke':'#242424'}" src="assets/svg/info.svg"></svg-icon>
                        </a>
                        <label for="document">E-mail</label>
                    </div>
                    <div class="input-material">
                        <input [type]="isEyeBool?'text':'password'" [(ngModel)]="authInput.password"
                               (keyup.enter)="onLogin()"
                               class="form-control" id="password"
                               autocomplete="off"
                               maxlength="50" name="password" required/>
                        <svg-icon (click)="isEyeBool=!isEyeBool" class="right"
                                  src="assets/svg/eye-password.svg"></svg-icon>
                        <label for="password">Senha</label>
                    </div>
                    <div class="w-100 mb-4 text-right">
                        <a href="javascript:void('');" (click)="handlerModalForgot()" class="btn btn-sm btn-outline-primary">Esqueci a senha</a>
                    </div>
                </form>
                <div class="row">
                    <div class="col-12">
                        <a href="javascript:void(0);" class="btn btn-primary btn-user btn-block" (click)="onLogin()">
                            Entrar
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal Forgot -->
<div class="modal fade" id="isForgot" tabindex="-1" role="dialog" aria-labelledby="loginLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" style="max-width: 454px" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <div class="modal-title modal_title">Esqueci a senha</div>
                <a type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body">
                <p class="modal_lbl modal_lbl-lg text-center">
                    Preencha no campo abaixo, utilize somente
                    números sem pontos e traço.</p>
                <form autocomplete="off">
                    <div class="input-material">
                        <input type="text" [(ngModel)]="authInput.email" class="form-control" id="forgot"
                               name="forgot" maxlength="100" required/>
                        <label for="forgot">CPF ou CNPJ</label>
                    </div>
                </form>
                <div class="row">
                    <div class="col-6">
                        <a href="javascript:void(0);" class="btn btn-outline-secondary btn-user btn-block"
                           data-dismiss="modal" aria-label="Close">
                            cancelar
                        </a>
                    </div>
                    <div class="col-6">
                        <a href="javascript:void(0);" class="btn btn-secondary btn-user btn-block" (click)="onForgot()">
                            Enviar
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal Description -->
<div class="modal fade" id="isLogin" tabindex="-1" role="dialog"
     aria-labelledby="loginLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="row no-gutters">
                <div class="col-4 p-3 bg-primary d-flex align-items-end">
                    <img class="modal_logo" src="assets/images/logo-modal.png" alt="logo">
                    <p class="modal-title modal_title">
                        Acessar o site do Gamerllil é fácil!
                    </p>
                </div>
                <div class="col">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <svg-icon [svgStyle]="{'fill':'#032246'}" src="assets/svg/close-circle.svg"></svg-icon>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="modal_lbl">ACESSO REVENDEDOR</p>
                        <p class="modal_text mb-5">
                            Insira seu CNPJ no campo “Login” e, confira nas nossas comunicações a senha para acesso.
                            Dúvidas entre em contato com <a class="text-primary" href="mailto:sac@designsprint.com.br">sac@designsprint.com.br</a> ou fale com o seu consultor. Caso já
                            tenha alterado a senha do primeiro acesso e esqueceu, volte para a página inicial e clique
                            em “Esqueci minha senha”.

                        </p>
                        <p class="modal_lbl">ACESSO EQUIPE DA REVENDA</p>
                        <p class="modal_text mb-5">
                            Insira seu CPF no campo “Login” e, confira nas nossas comunicações a senha para acesso.
                            Dúvidas entre em contato com <a class="text-primary" href="mailto:sac@designsprint.com.br">sac@designsprint.com.br</a> ou fale com sua revenda. Caso já
                            tenha alterado a senha do primeiro acesso e esqueceu, volte para a página inicial e clique
                            em “Esqueci minha senha”.
                        </p>
                        <p class="modal_lbl">ACESSO EQUIPE COMERCIAL</p>
                        <p class="modal_text">
                            Insira seu número de matrícula no campo “Login” e, confira nas nossas comunicações a senha
                            para acesso. Dúvidas entre em contato com <a class="text-primary" href="mailto:sac@designsprint.com.br">sac@designsprint.com.br</a>. Caso já tenha alterado
                            a senha do primeiro acesso e esqueceu, volte para a página inicial e clique em “Esqueci
                            minha senha”. Caso não saiba sua matrícula, entre em contato com o SAC.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<app-first-access [authType]="this.authType"></app-first-access>-->
<!--<app-profile [inputUser]="this.authType.user" [token]="this.authType.token"></app-profile>-->
