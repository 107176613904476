<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Organizar</p>
        <div class="container">
            <h2 class="page_header_title">Organizar</h2>
        </div>
    </div>
</div>
<div class="how">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/organize1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="page_subtitle mb-4 text-white"><strong>Organizar</strong></p>

                <p class="page_text text-white">
                    Antes de iniciar vocês precisarão ter a equipe certa e o desafio certo. Também precisarão de tempo e
                    espaço para conduzir um Gamerllil perfeito.</p>
                <img src="assets/images/organizeline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Organizar
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
               Antes do Sprint
            </span>
            <br>
            <span>
               <strong>Livro:</strong>
               Página 32
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                A grande ideia do <strong [routerLink]="'/explicar'">Gamerllil</strong> é construir e testar um
                <strong [routerLink]="'/prototipar'">protótipo</strong> em apenas cinco dias. Vocês terão uma pequena
                <strong [routerLink]="'/equipe'">equipe</strong> , <strong>montarão a agenda</strong> e progredirão rapidamente do <strong
                    [routerLink]="'/equipe'">desafio</strong> para a <strong [routerLink]="'/testar'">solução
                testada</strong> usando uma lista de verificação passo a passo comprovada.
                <br>
                <br>
                Um Gamerllil é como avançar rapidamente para o futuro, para que vocês possam ver como os clientes
                reagem antes de investirem todo o tempo e despesas na criação de um novo produto, serviço, campanha de
                marketing... Ou qualquer outra coisa!
                <br>
                <br>
                Mas o Gamerllil não é apenas sobre eficiência. Também é uma excelente maneira de interromper os
                antigos padrões do trabalho de escritório e substituí-los por uma maneira mais inteligente, divertida,
                respeitosa e eficaz de resolver problemas. Além disso, o método traz ainda as melhores contribuições de
                todos os membros da equipe, ajudando vocês a investirem seu tempo de trabalho no que realmente importa.
            </p>


            <p class="page_subtitle mb-4 "><strong>O DIA A DIA DO Gamerllil</strong></p>
            <br>
            <p>
                Na segunda-feira, vocês irão traçar um <a [routerLink]="'/conteudo/mapa-do-problema'">mapa do problema</a> e
                escolherão um <strong [routerLink]="'/alvo'">alvo</strong>. Na <strong [routerLink]="'/esbocar'">terça-feira</strong>,
                vocês irão <strong [routerLink]="'/esbocar'">esboçar soluções</strong>. Na <strong
                    [routerLink]="'/decidir'">quarta-feira</strong>, vocês irão <strong [routerLink]="'/decidir'">decidir</strong>
                quais esboços são mais fortes e farão um <strong [routerLink]="'/storyboard'">storyboard</strong>. Na
                <strong [routerLink]="'/prototipo'">quinta-feira</strong>, vocês construirão um <strong
                    [routerLink]="'/prototipo'">protótipo</strong> realista. E na <strong [routerLink]="'/testar'">sexta-feira</strong>,
                vocês irão <strong [routerLink]="'/testar'">testar</strong> esse protótipo com cinco clientes-alvo.
            </p>
            <img src="assets/images/organize2.png" alt="">
            <br>
            <br>
            <p>
                Claro, que o <strong [routerLink]="'/livro'">Livro Sprint</strong> do <strong
                    [routerLink]="'/jakeknapp'">Jake Knapp</strong> e o nosso <strong [routerLink]="'/curso'">Curso
                Online</strong> são guias muito mais completos para as atividades da etapa Organizar e o resto da semana
                do <strong [routerLink]="'/explicar'">Gamerllil</strong>. Mas, incluímos um checklist completo da
                etapa Organizar e algumas dicas para vocês logo abaixo. Também temos um vídeo onde explicamos o que
                acontece na etapa Organizar e mostramos alguns exemplos práticos.
                <br>
                Se mesmo assim vocês ainda tiverem dúvidas, confiram a página de <strong [routerLink]="'/faq'">Perguntas
                Frequentes</strong>. E, se não encontrarem as respostas que precisam, sintam-se à vontade para nos
                enviar uma mensagem através da nossa página de <strong [routerLink]="'/contato'">contato</strong>. Será
                sempre um prazer poder ajudá-los.
                <br>
                E, finalmente, aqui está o checklist da etapa Organizar que prometemos. Vem com a gente!
            </p>
            <br>
            <br>
            <p class="page_subtitle mb-4 "><strong>CHECKLIST</strong></p>

            <div class="faq-group">
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>Definir o desafio</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 41</p>

                        <span>Recorram a um Gamerllil quando muita coisa estiver em jogo, quando não houver tempo suficiente ou simplesmente quando se encontrarem em um beco sem saída.</span>
                        <br>

                        <a [routerLink]="'/desafio'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>Escolher o Definidor</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 47</p>

                        <span>Sem um Definidor, as decisões não serão levadas a sério. Se o Definidor não puder participar do Gamerllil inteiro, peçam a ele que aponte um representante que possa.</span>
                        <br>

                        <a [routerLink]="'/definidor'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>Recrutar a equipe</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 50</p>

                        <span>Sete pessoas ou menos. Acrescentem pessoas com habilidades diferentes ao grupo de indivíduos que já trabalham no projeto diariamente.</span>
                        <br>

                        <a [routerLink]="'/equipe'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>Convidar especialista</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 53</p>

                        <span>Nem todos poderão estar presentes durante os cinco dias. Para a tarde da segunda-feira, marquem entrevistas de quinze a trinta minutos com especialistas adicionais.</span>
                        <br>

                        <a [routerLink]="'/especialista'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>Escolher o facilitador</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 53</p>

                        <span>Ele administrará o tempo, as conversas e o processo do Gamerllil como um todo. Procurem alguém que tenha confiança para liderar uma reunião e sintetizar discussões.</span>
                        <br>

                        <a [routerLink]="'/Facilitador'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>Organizar a agenda</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 57</p>

                        <span>Marquem com a equipe de Gamerllil o período das dez da manhã às cinco da tarde da segunda-feira até a quinta, e das nove às cinco na sexta-feira.</span>
                        <br>

                        <a [routerLink]="'/agenda'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>Reservar as salas</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 59</p>

                        <span>Reservem uma sala que a equipe do Gamerllil possa usar durante toda a semana. E uma segunda sala para as entrevistas da sexta-feira.</span>
                        <br>

                        <a [routerLink]="'/sala'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>Separar os materiais</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <p>⌼ - ⌻ - ⌺ Stakeholders ⌹ 62</p>

                        <span>Antes de começarem o Gamerllil, vocês irão precisar de alguns materiais básicos de escritório.</span>
                        <br>

                        <a [routerLink]="'/materiais'">Saiba Mais --></a>
                    </div>
                </div>


            </div>
            <br>
            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE Gamerllil. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/desafio'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

