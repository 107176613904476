import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AccessDashboard {
  createdAt: Scalars['String'];
  total: Scalars['Float'];
};

export class ActiveUsers {
  name: Scalars['String'];
  total: Scalars['Float'];
};

export class AnswerContactInput {
  answer: Scalars['String'];
  contactId: Scalars['String'];
};

export class AuthInput {
  email: Scalars['String'];
  password: Scalars['String'];
  /** admin|user */
  type?: InputMaybe<Scalars['String']>;
};

export class AuthType {
  token: Scalars['String'];
  user: User;
};

export class Banner {
  bannerAccess?: Maybe<Array<BannerAccess>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  pathImage?: Maybe<Scalars['String']>;
  pathLink?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  profile?: Maybe<Scalars['String']>;
  scheduled: Scalars['Boolean'];
  start?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class BannerAccess {
  banner?: Maybe<Banner>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user?: Maybe<User>;
};

export class BannerDashboard {
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export class BannerPage {
  banner: Array<Banner>;
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CategoryDownload {
  download?: Maybe<Array<Download>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export class ChangePasswordInput {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export class Contact {
  answer?: Maybe<Scalars['String']>;
  answeredAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  subject: Subject;
  user?: Maybe<User>;
  userAdmin?: Maybe<User>;
};

export class ContactCount {
  noAnswered: Scalars['Float'];
  total: Scalars['Float'];
};

export class ContactPage {
  contact: Array<Contact>;
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class CreateBannerAccessInput {
  bannerId: Scalars['String'];
};

export class CreateBannerInput {
  end?: InputMaybe<Scalars['DateTime']>;
  highlight: Scalars['Boolean'];
  pathImage?: InputMaybe<Scalars['String']>;
  pathLink?: InputMaybe<Scalars['String']>;
  position: Scalars['Float'];
  scheduled: Scalars['Boolean'];
  start?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
};

export class CreateContactInput {
  answer?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  jobRole?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  profile?: InputMaybe<Scalars['String']>;
  subjectId: Scalars['String'];
};

export class CreateDownloadAccessInput {
  downloadId: Scalars['String'];
};

export class CreateDownloadInput {
  categoryId: Scalars['String'];
  extension?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  profile: Scalars['String'];
};

export class CreateNewsAccessInput {
  /** Id news */
  newsId: Scalars['String'];
};

export class CreateNewsInput {
  /** Schedule date end */
  end?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  /** PathImage news */
  pathImage?: InputMaybe<Scalars['String']>;
  /** Schedule news */
  scheduled: Scalars['Boolean'];
  /** Schedule date start */
  start?: InputMaybe<Scalars['DateTime']>;
  /** Text news */
  text?: InputMaybe<Scalars['String']>;
  /** Title news */
  title: Scalars['String'];
  /** ENUM news|case|article|other */
  type: Scalars['String'];
};

export class CreateUserAdminInput {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export class CreateUserInput {
  birthday?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class Download {
  category?: Maybe<CategoryDownload>;
  createdAt: Scalars['DateTime'];
  downloadAccess?: Maybe<Array<DownloadAccess>>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  profile: Scalars['String'];
};

export class DownloadAccess {
  createdAt: Scalars['DateTime'];
  download?: Maybe<Download>;
  id: Scalars['ID'];
  user: User;
};

export class DownloadDashboard {
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export class DownloadPage {
  currentPage: Scalars['Int'];
  downloads: Array<Download>;
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Mutation {
  answerContact: DefaultMessage;
  changePassword: DefaultMessage;
  clickBanner: DefaultMessage;
  clickDownload: DefaultMessage;
  clickNewsAccess: DefaultMessage;
  /** Create Admin */
  createAdmin: DefaultMessage;
  createBanner: Banner;
  createContact: DefaultMessage;
  createDownload: Download;
  createNews: News;
  /** Create User */
  createUser: TokenDto;
  /** Delete Admin */
  deleteAdmin: DefaultMessage;
  forgotPassword: DefaultMessage;
  login: AuthType;
  removeBanner: DefaultMessage;
  removeDownload: DefaultMessage;
  removeNews: DefaultMessage;
  resetPassword: DefaultMessage;
  /** Update User */
  selfUpdateUser: User;
  updateBanner: Banner;
  updateDownload: Download;
  updateNews: News;
};


export class MutationAnswerContactArgs {
  data: AnswerContactInput;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationClickBannerArgs {
  data: CreateBannerAccessInput;
};


export class MutationClickDownloadArgs {
  data: CreateDownloadAccessInput;
};


export class MutationClickNewsAccessArgs {
  data: CreateNewsAccessInput;
};


export class MutationCreateAdminArgs {
  data: CreateUserAdminInput;
};


export class MutationCreateBannerArgs {
  data: CreateBannerInput;
};


export class MutationCreateContactArgs {
  data: CreateContactInput;
};


export class MutationCreateDownloadArgs {
  data: CreateDownloadInput;
};


export class MutationCreateNewsArgs {
  data: CreateNewsInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationDeleteAdminArgs {
  id: Scalars['String'];
};


export class MutationForgotPasswordArgs {
  cpf: Scalars['String'];
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationRemoveDownloadArgs {
  id: Scalars['String'];
};


export class MutationRemoveNewsArgs {
  id: Scalars['String'];
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationSelfUpdateUserArgs {
  data: UpdateUserInput;
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerInput;
};


export class MutationUpdateDownloadArgs {
  data: UpdateDownloadInput;
};


export class MutationUpdateNewsArgs {
  data: UpdateNewsInput;
};

export class News {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  friendlyUrl?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  newsAccess?: Maybe<Array<NewsAccess>>;
  pathImage?: Maybe<Scalars['String']>;
  scheduled: Scalars['Boolean'];
  start?: Maybe<Scalars['DateTime']>;
  text: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class NewsAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  news?: Maybe<News>;
  user?: Maybe<User>;
};

export class NewsDashboard {
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export class NewsPage {
  currentPage: Scalars['Int'];
  news: Array<News>;
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Query {
  /** Return total access users */
  accessDashboard: Array<AccessDashboard>;
  /** Return active and total users for profile. */
  activeUsersDashboard: Array<ActiveUsers>;
  banner: Banner;
  /** Return total access banner. */
  bannerDashboard: Array<BannerDashboard>;
  banners: Array<Banner>;
  categoryDownload: Array<CategoryDownload>;
  contact: Contact;
  /** Return total contacts. */
  contactDashboard: Array<ContactCount>;
  contacts: Array<Contact>;
  /** Return User Logged */
  currentUser: User;
  download: Download;
  downloadByCategory: Array<Download>;
  /** Return total access download. */
  downloadDashboard: Array<DownloadDashboard>;
  downloads: Array<Download>;
  homeBanners: Array<Banner>;
  homeNews: NewsPage;
  /** List Admin */
  listUserAdmin: UserPage;
  new: News;
  newByFriendlyUrl: News;
  news: NewsPage;
  /** Return total access news. */
  newsDashboard: Array<NewsDashboard>;
  /** Return total users for resales. */
  resalesDashboard: Array<ResaleCount>;
  searchBanner: BannerPage;
  searchContact: ContactPage;
  searchDownload: DownloadPage;
  searchNews: NewsPage;
  subject: Array<Subject>;
};


export class QueryBannerArgs {
  id: Scalars['String'];
};


export class QueryContactArgs {
  id: Scalars['String'];
};


export class QueryDownloadArgs {
  id: Scalars['String'];
};


export class QueryDownloadByCategoryArgs {
  categoryId: Scalars['String'];
};


export class QueryHomeNewsArgs {
  page: Scalars['Float'];
  type: Scalars['String'];
};


export class QueryListUserAdminArgs {
  data: SearchUserInput;
};


export class QueryNewArgs {
  id: Scalars['String'];
};


export class QueryNewByFriendlyUrlArgs {
  friendlyUrl: Scalars['String'];
};


export class QueryNewsArgs {
  page: Scalars['Float'];
  type: Scalars['String'];
};


export class QuerySearchBannerArgs {
  data: SearchBannerInput;
};


export class QuerySearchContactArgs {
  data: SearchContactInput;
};


export class QuerySearchDownloadArgs {
  data: SearchDownloadInput;
};


export class QuerySearchNewsArgs {
  data: SearchNewsInput;
};

export class ResaleCount {
  name: Scalars['String'];
  region: Scalars['String'];
  total: Scalars['Float'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class SearchBannerInput {
  end: Scalars['DateTime'];
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
};

export class SearchContactInput {
  email?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  status?: InputMaybe<Scalars['String']>;
};

export class SearchDownloadInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export class SearchNewsInput {
  end: Scalars['DateTime'];
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start: Scalars['DateTime'];
  /** ENUM news|case|article */
  type: Scalars['String'];
};

export class SearchUserInput {
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export class Subject {
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  subjectContato?: Maybe<Array<Contact>>;
};

export class TokenDto {
  token: Scalars['String'];
  user: User;
};

export class UpdateBannerInput {
  end?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  pathImage?: InputMaybe<Scalars['String']>;
  pathLink?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Float']>;
  scheduled?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateDownloadInput {
  categoryId?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Scalars['String']>;
};

export class UpdateNewsInput {
  /** Schedule date end */
  end?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** PathImage news */
  pathImage?: InputMaybe<Scalars['String']>;
  /** Schedule news */
  scheduled: Scalars['Boolean'];
  /** Schedule date start */
  start?: InputMaybe<Scalars['DateTime']>;
  /** Text news */
  text?: InputMaybe<Scalars['String']>;
  /** Title news */
  title: Scalars['String'];
  /** ENUM news|case|article */
  type: Scalars['String'];
};

export class UpdateUserInput {
  birthday?: InputMaybe<Scalars['String']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export class User {
  access?: Maybe<Array<UserAccess>>;
  birthday?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedById?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobRole?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pathImage?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  user: User;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type UserPageFragment = { currentPage: number, nextPage: boolean, totalCount: number, pageSize: number, previousPage: boolean, totalPage: number, results?: Array<{ id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null }> | null };

export type UserFragment = { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null };

export type DefaultMessageFragment = { message: string, status: number };

export type NewsPageFragment = { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, news: Array<{ id: string, title: string, pathImage?: string | null, text: string, createdAt: any, deletedAt?: any | null, friendlyUrl?: string | null, type: string }> };

export type NewsFragment = { id: string, title: string, pathImage?: string | null, text: string, createdAt: any, deletedAt?: any | null, friendlyUrl?: string | null, type: string };

export type DownloadPageFragment = { totalCount: number, pageSize: number, currentPage: number, totalPage: number, nextPage: boolean, downloads: Array<{ id: string, name: string, path?: string | null, profile: string, createdAt: any, extension?: string | null, category?: { id: string, name: string } | null, downloadAccess?: Array<{ id: string }> | null }> };

export type DownloadFragment = { id: string, name: string, path?: string | null, profile: string, createdAt: any, extension?: string | null, category?: { id: string, name: string } | null, downloadAccess?: Array<{ id: string }> | null };

export type SubjectFragment = { id: string, name: string, createdAt: any, deletedAt: any, subjectContato?: Array<{ id: string, name: string, email: string, phone: string, message: string, answer?: string | null, createdAt: any, answeredAt?: any | null, user?: { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null } | null, subject: { id: string, name: string, createdAt: any, deletedAt: any }, userAdmin?: { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null } | null }> | null };

export type HomeBannersQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeBannersQuery = { homeBanners: Array<{ id: string, title: string, createdAt: any, deletedAt?: any | null, pathImage?: string | null, pathLink?: string | null }> };

export type ClickBannerMutationVariables = Exact<{
  input: CreateBannerAccessInput;
}>;


export type ClickBannerMutation = { clickBanner: { message: string, status: number } };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { createContact: { message: string, status: number } };

export type SubjectQueryVariables = Exact<{ [key: string]: never; }>;


export type SubjectQuery = { subject: Array<{ id: string, name: string }> };

export type CategoryDownloadQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoryDownloadQuery = { categoryDownload: Array<{ id: string, name: string }> };

export type DownloadByCategoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DownloadByCategoryQuery = { downloadByCategory: Array<{ id: string, name: string, path?: string | null, profile: string, extension?: string | null, category?: { id: string, name: string } | null }> };

export type ClickDownloadMutationVariables = Exact<{
  input: CreateDownloadAccessInput;
}>;


export type ClickDownloadMutation = { clickDownload: { message: string, status: number } };

export type SearchDownloadQueryVariables = Exact<{
  input: SearchDownloadInput;
}>;


export type SearchDownloadQuery = { searchDownload: { totalCount: number, pageSize: number, currentPage: number, totalPage: number, nextPage: boolean, downloads: Array<{ id: string, name: string, path?: string | null, profile: string, createdAt: any, extension?: string | null, category?: { id: string, name: string } | null, downloadAccess?: Array<{ id: string }> | null }> } };

export type LoginMutationVariables = Exact<{
  input: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null } } };

export type ForgotPasswordMutationVariables = Exact<{
  cpf: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string, status: number } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string, status: number } };

export type NewQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type NewQuery = { new: { id: string, title: string, pathImage?: string | null, text: string, createdAt: any, deletedAt?: any | null, friendlyUrl?: string | null, type: string } };

export type NewsQueryVariables = Exact<{
  page: Scalars['Float'];
  type: Scalars['String'];
}>;


export type NewsQuery = { news: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, news: Array<{ id: string, title: string, pathImage?: string | null, text: string, createdAt: any, deletedAt?: any | null, friendlyUrl?: string | null, type: string }> } };

export type HomeNewsQueryVariables = Exact<{
  page: Scalars['Float'];
  type: Scalars['String'];
}>;


export type HomeNewsQuery = { homeNews: { currentPage: number, nextPage: boolean, pageSize: number, previousPage: boolean, totalCount: number, totalPage: number, news: Array<{ id: string, title: string, pathImage?: string | null, text: string, createdAt: any, deletedAt?: any | null, friendlyUrl?: string | null, type: string }> } };

export type ClickNewsAccessMutationVariables = Exact<{
  input: CreateNewsAccessInput;
}>;


export type ClickNewsAccessMutation = { clickNewsAccess: { message: string, status: number } };

export type NewByFriendlyUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type NewByFriendlyUrlQuery = { newByFriendlyUrl: { id: string, title: string, pathImage?: string | null, text: string, createdAt: any, deletedAt?: any | null, friendlyUrl?: string | null, type: string } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null } };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { changePassword: { message: string, status: number } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { createUser: { token: string, user: { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null } } };

export type SelfUpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type SelfUpdateUserMutation = { selfUpdateUser: { id: string, name?: string | null, email?: string | null, cpf?: string | null, birthday?: string | null, phone?: string | null, pathImage?: string | null } };

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  cpf
  birthday
  phone
  pathImage
}
    `;
export const UserPageFragmentDoc = gql`
    fragment UserPage on UserPage {
  currentPage
  nextPage
  totalCount
  pageSize
  previousPage
  totalPage
  results {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const NewsFragmentDoc = gql`
    fragment News on News {
  id
  title
  pathImage
  text
  createdAt
  deletedAt
  friendlyUrl
  type
}
    `;
export const NewsPageFragmentDoc = gql`
    fragment NewsPage on NewsPage {
  currentPage
  nextPage
  pageSize
  previousPage
  totalCount
  totalPage
  news {
    ...News
  }
}
    ${NewsFragmentDoc}`;
export const DownloadFragmentDoc = gql`
    fragment Download on Download {
  id
  name
  path
  profile
  createdAt
  extension
  category {
    id
    name
  }
  downloadAccess {
    id
  }
}
    `;
export const DownloadPageFragmentDoc = gql`
    fragment DownloadPage on DownloadPage {
  totalCount
  pageSize
  currentPage
  totalPage
  nextPage
  downloads {
    ...Download
  }
}
    ${DownloadFragmentDoc}`;
export const SubjectFragmentDoc = gql`
    fragment Subject on Subject {
  id
  name
  createdAt
  deletedAt
  subjectContato {
    id
    user {
      ...User
    }
    name
    email
    phone
    subject {
      id
      name
      createdAt
      deletedAt
    }
    message
    answer
    userAdmin {
      ...User
    }
    createdAt
    answeredAt
  }
}
    ${UserFragmentDoc}`;
export const HomeBannersDocument = gql`
    query HomeBanners {
  homeBanners {
    id
    title
    createdAt
    deletedAt
    pathImage
    pathLink
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HomeBannersGQL extends Apollo.Query<HomeBannersQuery, HomeBannersQueryVariables> {
    document = HomeBannersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClickBannerDocument = gql`
    mutation ClickBanner($input: CreateBannerAccessInput!) {
  clickBanner(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ClickBannerGQL extends Apollo.Mutation<ClickBannerMutation, ClickBannerMutationVariables> {
    document = ClickBannerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
    document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubjectDocument = gql`
    query Subject {
  subject {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubjectGQL extends Apollo.Query<SubjectQuery, SubjectQueryVariables> {
    document = SubjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoryDownloadDocument = gql`
    query CategoryDownload {
  categoryDownload {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryDownloadGQL extends Apollo.Query<CategoryDownloadQuery, CategoryDownloadQueryVariables> {
    document = CategoryDownloadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadByCategoryDocument = gql`
    query DownloadByCategory($id: String!) {
  downloadByCategory(categoryId: $id) {
    id
    name
    path
    profile
    extension
    category {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadByCategoryGQL extends Apollo.Query<DownloadByCategoryQuery, DownloadByCategoryQueryVariables> {
    document = DownloadByCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClickDownloadDocument = gql`
    mutation ClickDownload($input: CreateDownloadAccessInput!) {
  clickDownload(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ClickDownloadGQL extends Apollo.Mutation<ClickDownloadMutation, ClickDownloadMutationVariables> {
    document = ClickDownloadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchDownloadDocument = gql`
    query SearchDownload($input: SearchDownloadInput!) {
  searchDownload(data: $input) {
    ...DownloadPage
  }
}
    ${DownloadPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchDownloadGQL extends Apollo.Query<SearchDownloadQuery, SearchDownloadQueryVariables> {
    document = SearchDownloadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($input: AuthInput!) {
  login(data: $input) {
    user {
      ...User
    }
    token
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($cpf: String!) {
  forgotPassword(cpf: $cpf) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewDocument = gql`
    query New($id: String!) {
  new(id: $id) {
    ...News
  }
}
    ${NewsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewGQL extends Apollo.Query<NewQuery, NewQueryVariables> {
    document = NewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewsDocument = gql`
    query News($page: Float!, $type: String!) {
  news(page: $page, type: $type) {
    ...NewsPage
  }
}
    ${NewsPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewsGQL extends Apollo.Query<NewsQuery, NewsQueryVariables> {
    document = NewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HomeNewsDocument = gql`
    query HomeNews($page: Float!, $type: String!) {
  homeNews(page: $page, type: $type) {
    ...NewsPage
  }
}
    ${NewsPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HomeNewsGQL extends Apollo.Query<HomeNewsQuery, HomeNewsQueryVariables> {
    document = HomeNewsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClickNewsAccessDocument = gql`
    mutation ClickNewsAccess($input: CreateNewsAccessInput!) {
  clickNewsAccess(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ClickNewsAccessGQL extends Apollo.Mutation<ClickNewsAccessMutation, ClickNewsAccessMutationVariables> {
    document = ClickNewsAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewByFriendlyUrlDocument = gql`
    query NewByFriendlyUrl($url: String!) {
  newByFriendlyUrl(friendlyUrl: $url) {
    ...News
  }
}
    ${NewsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewByFriendlyUrlGQL extends Apollo.Query<NewByFriendlyUrlQuery, NewByFriendlyUrlQueryVariables> {
    document = NewByFriendlyUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    document = ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(data: $input) {
    user {
      ...User
    }
    token
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelfUpdateUserDocument = gql`
    mutation SelfUpdateUser($input: UpdateUserInput!) {
  selfUpdateUser(data: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelfUpdateUserGQL extends Apollo.Mutation<SelfUpdateUserMutation, SelfUpdateUserMutationVariables> {
    document = SelfUpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }