<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Jake Knapp</p>
        <div class="container">
            <h2 class="page_header_title">Jake Knapp</h2>
        </div>
    </div>
</div>
<div class="flex-row bg-story align-items-center d-flex w-full">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5 justify-content-around">

            <div class="col-sm-4">
                <p class="page_employee_value mb-4"><span>QUEM É<br>JAKE KNAPP</span></p>

                <p class="page_employee_text-sm color-white">Jake Knapp passou 10 anos no Google e no Google Ventures,
                    onde criou o Gamerllil. Ele escreveu dois livros, Sprint e Make Time , treinou equipes em
                    lugares como Slack, LEGO, IDEO e NASA, e foi instrutor convidado no MIT e na Harvard Business
                    School.</p>
            </div>
            <div class="col-sm-4">
                <img src="assets/images/jakeknapp-banner.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container mt-4">
    <div class="row align-items-center mb-5">
        <div class="col-sm-6">
            <img src="assets/images/jakeknapp1.png" alt="">
        </div>

        <div class="col-sm-6">
            <p class="page_subtitle mb-4"><span>Início da <br>carreira</span></p>

            <p class="page_text">Criado pelo designer Jake Knapp em 2010, o Gamerllil é o método usado no Google
                para testar e aplicar novas ideias em apenas cinco dias.</p>
        </div>

    </div>

</div>
<div class="bg-gray w-full">
    <div class="container">
        <div class="row no-gutters  align-items-center mb-5">

            <div class="col-sm-6">
                <p class="page_subtitle mb-4"><span> Sua chegada <br>no Google</span></p>

                <p class="page_text">
                    Logo após sua passagem pela Microsoft, Jake Knapp começou uma nova jornada profissional no Google,
                    onde criou o Gamerllil em 2010. Em 2012, Jake foi para o Google Ventures, e recebeu várias
                    contribuições importantes da equipe para o aperfeiçoamento do método.</p>
            </div>

            <div class="col-sm-6 my-5">
                <img src="assets/images/jakeknapp2.png" alt="">
            </div>
        </div>

    </div>
</div>

<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <img src="assets/images/jakeknapp3.png" alt="">
        </div>

        <div class="col-sm-6">
            <p class="page_subtitle mb-4"><span>Utilizando o <br> Gamerllil</span></p>

            <p class="page_text">
                Em sua passagem pelo Gooogle, Jake Knapp foi responsável pela estratégia de design da empresa,
                utilizando o Gamerllil para tudo. Desde o Google X, Google Chrome e Gmail. Foi ele também quem
                começou o Google Hangouts, atualmente chamado de Google Meet.</p>
        </div>


    </div>

</div>

<div class="bg-gray w-full">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">

            <div class="col-sm-6">
                <p class="page_subtitle mb-4"><br><span>Publicação do <br>livro oficial</span></p>

                <p class="page_text">
                    De 2012 à 2013, a equipe do Google Ventures publicou uma série de instruções sobre o método, que
                    começou a se espalhar rapidamente. Em 2016 Jake Knapp publicou o <span [routerLink]="'/livro'"> livro oficial</span>
                    e, hoje, milhares de equipes ao redor do mundo realizam o Gamerllil em startups e grandes
                    corporações.</p>
            </div>

            <div class="col-sm-6 my-4">
                <img src="assets/images/jakeknapp4.png" alt="">
            </div>

        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <img src="assets/images/jakeknapp5.png" alt="">
        </div>

        <div class="col-sm-6">
            <p class="page_subtitle mb-4"><br><span>O que faz <br>atualmente</span></p>

            <p class="page_text">
                Atualmente Jake Knapp é fundador da <span>Character</span>, onde ajuda startups a criarem produtos
                adequados ao mercado através do Gamerllil. Jake está entre os designers mais conceituados do mundo,
                e está tentando escrever novos livros quando seus filhos estão na escola. </p>
        </div>

    </div>

</div>

<div class=" bg-story d-flex w-full ">
    <div class="container">
        <div class="flex-column no-gutters d-flex align-items-center my-5">
            <div class="col-sm-6">
                <img src="assets/images/knowmore.png" alt="">
            </div>
            <div class="col-sm-6 align-items-center d-flex flex-column">
                <p class="page_employee_value mb-4 text-center"> <br><span>  Quer saber mais?</span> </p>

                <p class="page_employee_lbl text-center">Mesmo com o livro passo a passo do Jake Knapp e com todo o conteúdo disponível de graça na internet, não podemos negar que o início da jornada do Facilitador de Gamerllil não costuma ser fácil - assim como não deve estar sendo para você. Dessa forma, o nosso maior objetivo aqui é compartilhar nossa experiência com o Gamerllil para ajudar você que pretende se tornar um facilitador de sucesso em pouco tempo, de maneira simples, inteligente e eficaz.</p>
                <button class="button-know" role="button">Quero saber mais!</button>
            </div>
        </div>

    </div>
</div>









