<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Sobre</p>
        <div class="container">
            <h2 class="page_header_title">Sobre</h2>
        </div>
    </div>
</div>
<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <p class="page_subtitle mb-4">O que é  <br><span>  Gamerllil?</span> </p>

            <p class="page_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</p>
        </div>
        <div class="col-sm-6">
            <img style="width: 300px" src="assets/images/game1.png" alt="">
        </div>
    </div>

</div>
<div class="container">
    <div class="row no-gutters align-items-center mb-5">


        <div class="col-sm-6">
            <img style="width: 300px" src="assets/images/game2.jpg" alt="">
        </div>
        <div class="col-sm-6">
            <p class="page_subtitle mb-4">Mas afinal, o que é  <br><span>  Gamerllil?</span> </p>

            <p class="page_text">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</p>
        </div>
    </div>

</div>

<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <p class="page_subtitle mb-4">Para quem é o <br><span>  Gamerllil?</span> </p>

            <p class="page_text">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic</p>
        </div>
        <div class="col-sm-6">
            <img style="width: 300px" src="assets/images/game3.jpg" alt="">
        </div>

    </div>

</div>











