<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">História</p>
        <div class="container">
            <h2 class="page_header_title">História</h2>
        </div>
    </div>
</div>
<div class="flex-row bg-story align-items-center d-flex w-full">
    <div class="container">
        <div class="row no-gutters align-items-center my-5 justify-content-around">

            <div class="col-sm-4">
                <p class="page_employee_value mb-4"><span>A HISTÓRIA <br>DO Gamerllil</span></p>

                <p class="page_employee_text-sm color-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
            </div>
            <div class="col-sm-4">
                <img src="assets/images/game1.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-6 mt-3">
            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
            <div class="my-4 mbg">
                <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                    <span>Notas (2)</span>
                    <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                </a>
                <div id="request-1" class="collapse">
                    <p class="faq-anwser">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p class="faq-anwser">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum..</p>
                    <p class="faq-anwser">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-story d-flex w-full ">
    <div class="container">
        <div class="flex-column no-gutters d-flex align-items-center my-5">

            <div class="col-sm-6 align-items-center d-flex flex-column">
                <p class="page_employee_value mb-4 text-center"> <br><span>  Quer saber mais?</span> </p>

                <p class="page_employee_lbl text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <button class="button-know" role="button">Quero saber mais!</button>
            </div>
        </div>

    </div>
</div>


