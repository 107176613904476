<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">DECIDIR</p>
        <div class="container">
            <h2 class="page_header_title">DECIDIR</h2>
        </div>
    </div>
</div>
<div class="map">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/decide1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="page_subtitle mb-4 text-white"><strong>Organizar</strong></p>

                <p class="page_text text-white">
                    Na quarta-feira do Gamerllil, vocês terão uma pilha de soluções. Agora, vocês deverão decidir quais desses esboços devem ser prototipados e testados.</p>
                <img src="assets/images/decideline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Decidir
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
             Quarta-feira
            </span>
            <br>
            <span>
               <strong>Livro:</strong>
              Página 156
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                Na quarta-feira de manhã do <strong [routerLink]="'/explain'">Gamerllil</strong>, vocês terão uma pilha de soluções com base em seus <strong [routerLink]="'/esbocar'">esboços</strong>  de terça-feira. Isso é ótimo, mas também é um problema. Vocês não terão como <strong [routerLink]="'/prototipar'">prototipar</strong> e <strong [routerLink]="'/testar'">testar</strong> todos eles. Por isso, vocês precisão de um plano sólido. Pela manhã, vocês criticarão cada solução e decidirão quais têm a melhor chance de alcançar o <strong [routerLink]="'/definirobjetivo'">objetivo de longo prazo</strong> . Então, à tarde, vocês pegarão as cenas vencedoras de seus esboços e as transformarão em um <strong [routerLink]="'/storyboard'">storyboard</strong> - um roteiro passo a passo para o protótipo.
            </p>

            <br>
            <img src="assets/images/decide2.png" alt="">
            <br>
            <br>
            <p>
                Claro, que o <strong [routerLink]="'/livro'">Livro Sprint</strong>  do <strong [routerLink]="'/jakeknapp'">Jake Knapp</strong>  e o nosso  <strong [routerLink]="'/curso'">Curso Online</strong> são guias muito mais completos para as atividades da etapa Decidir e o resto da semana do <strong [routerLink]="'/explicar'">Gamerllil.</strong> Mas, incluímos um checklist completo da etapa Decidir e algumas dicas para vocês logo abaixo. Também temos um vídeo onde explicamos o que acontece na etapa Decidir e mostramos alguns exemplos práticos.
                <br>
                <br>
                Se mesmo assim vocês ainda tiverem dúvidas, confiram a página de <strong [routerLink]="'/faq'">Perguntas Frequentes</strong>. E, se não encontrarem as respostas que precisam, sintam-se à vontade para nos enviar uma mensagem através da nossa página de <strong [routerLink]="'/contato'">contato</strong>. Será sempre um prazer poder ajudá-los.
                <br>
                <br>
                E, finalmente, aqui está o checklist da etapa Decidir que prometemos. Vem com a gente!
            </p>

            <br>
            <br>
            <p class="page_subtitle mb-4 "><strong>CHECKLIST</strong></p>
            <span><strong>Lembrete 1:</strong> Os horários são aproximados. Não se preocupem se vocês precisarem adiantar um pouco, atrasar um pouco ou se precisarem fazer alguns ajustes ao longo das atividades.</span>
            <br>
            <span><strong>Lembrete 2:</strong> Façam um intervalo para o almoço das 13h00 às 14h00 todos os dias. Comam juntos se for possível (é divertido). Escolham pratos leves para conservar a energia à tarde. Haverá lanchinhos se ficarem com fome depois.</span>
            <br>
            <span><strong>Lembrete 3:</strong> Façam pausas a cada sessenta ou a cada noventa minutos. Por volta das 11h00 e 15h45 todos os dias.</span>
            <br>
            <br>
            <div class="faq-group">

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>Museu de arte</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p>⌼ 10:00h     ⌻ 15min     ⌺ Todos     ⌹ 165</p>

                        <span>Na manhã da quarta-feira, ninguém terá visto os esboços da solução ainda, então colem todos eles em uma longa fileira na parede.</span>
                        <br>

                        <a [routerLink]="'/museu'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>Mapa de calor</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p>⌼ 10:15h     ⌻ 15min     ⌺ Todos     ⌹ 165</p>

                        <span>Cada membro da equipe analisará os esboços em silêncio e colará de um a três adesivos de bolinha ao lado das partes que mais gostarem.</span>
                        <br>

                        <a [routerLink]="'/calor'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>Críticas-relâmpago</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p>⌼ 10:30h     ⌻ 30min     ⌺ Todos     ⌹ 168</p>

                        <span>Discutam em grupo os destaques de cada solução. Registrem as ideias mais promissoras e objeções importantes. No fim, perguntem ao criador do esboço se vocês deixaram passar alguma coisa.</span>
                        <br>

                        <a [routerLink]="'/critica'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>Intenção de voto</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p>⌼ 11:15h     ⌻ 30min     ⌺ Todos     ⌹ 171</p>

                        <span>Vocês escolherão sua ideia favorita em silêncio. Todos ao mesmo tempo colocam um adesivo grande de bolinha para registrar seu voto (que ainda não será definitivo).</span>
                        <br>

                        <a [routerLink]="'/intencao'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>Supervoto</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p>⌼ 11:45h     ⌻ 15min     ⌺ Todos     ⌹ 174</p>

                        <span>O Facilitador dará três adesivos grandes de bolinha ao Definidor com as iniciais do nome dele. As escolhas dele serão transformadas em protótipo e testadas.</span>
                        <br>

                        <a [routerLink]="'/supervoto'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>Separar os vencedores</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p>⌼ 12:00h     ⌻ 15min     ⌺ Todos     ⌹ 174</p>

                        <span>Separem os esboços que receberam os supervotos. Não descartem os outros - coloquem-nos na categoria “talvez mais tarde”.</span>
                        <br>

                        <a [routerLink]="'/vencedores'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>Batalhar ou encaixar</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p>⌼ 12:15h     ⌻ 15min     ⌺ Todos     ⌹ 179</p>

                        <span>Decidam se os esboços vencedores se encaixam em um mesmo protótipo, ou se ideias conflitantes requerem dois ou três protótipos concorrentes em uma Batalha.</span>
                        <br>

                        <a [routerLink]="'/batalhar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>Inventar nomes</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <p>⌼ 12:30h     ⌻ 30min     ⌺ Todos     ⌹ 180</p>

                        <span>Inventem marcas fictícias para que os protótipos concorrentes tenham nomes e aparências diferentes, fazendo com que os clientes posam distingui-los.</span>
                        <br>

                        <a [routerLink]="'/inventar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-9')" href="javascript:void(0)">
                        <span>Painel do storyboard</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-9" class="collapse">
                        <p>⌼ 14:00h     ⌻ 15min     ⌺ Todos     ⌹ 187</p>

                        <span>Antes de começarem o storyboard, vocês precisarão de um grande painel com quinze retângulos. Desenhem os retângulos em um quadro branco vazio, cada um com mais ou menos o tamanho de duas folhas de papel A4.</span>
                        <br>

                        <a [routerLink]="'/storyboard'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-10')" href="javascript:void(0)">
                        <span>Escolher a abertura</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-10" class="collapse">
                        <p>⌼ 14:15h     ⌻ 15min     ⌺ Todos     ⌹ 189</p>

                        <span>Pensem em como os clientes geralmente encontram seus produtos ou serviços. A cena de abertura deve ser simples: busca na internet, matéria de revista, prateleira de loja, etc.</span>
                        <br>

                        <a [routerLink]="'/abertura'">Saiba Mais --></a>
                    </div>
                </div>


                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-11')" href="javascript:void(0)">
                        <span>Preencher o storyboard</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-11" class="collapse">
                        <p>⌼ 14:30h     ⌻ 150min     ⌺ Todos     ⌹ 190</p>

                        <span>Se possível, coloquem esboços prontos no storyboard, se não puderem, desenhem. Não escrevam todos juntos. Incluam apenas o número suficiente de detalhes para ajudar a equipe a criar o protótipo. A história deve ter entre cinco e quinze etapas.</span>
                        <br>

                        <a [routerLink]="'/storyboard'">Saiba Mais --></a>
                    </div>
                </div>





            </div>
            <br>


            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE Gamerllil. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn" [routerLink]="'/esbocarsolucoes'">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/museu'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

