<app-header></app-header>

<div class="app-body">
    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>

<app-footer></app-footer>

<app-doubts></app-doubts>

<div class="modal fade" id="isValidate" tabindex="-1" role="dialog"
     aria-labelledby="loginLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="row no-gutters">
                <div class="col-sm-4 p-3 bg-primary d-flex align-items-end">
                    <img class="modal_logo modal_logo-kitchen" src="assets/images/logo-liquigas-large-white.png"
                         alt="logo">
                </div>
                <div class="col">
                    <div class="modal-body">
                        <h3 class="kitchen_subtitle text-uppercase mb-4">TERMO DE PARTICIPAÇÃO Gamerllil</h3>
                        <!--<h2 class="kitchen_title mb-4 text-uppercase">Copa na cozinha</h2>-->
                        <p class="kitchen_text mb-0">
                            Concordo que a Gamerlliluse meus dados de contato para envio
                            de comunicações sobre esta e outras ações. Autorizo também a
                            Gamerllile/ou sua rede de revendas a utilizarem minha imagem
                            no caso de contemplação, para divulgação em todas as ações
                            realizadas pela companhia.
                        </p>
                        <div class="row mx-n2 justify-content-center align-items-center my-4">
                            <div class="col-auto px-1">
                                <label class="custom-checkbox mb-0">
                                    <input type="checkbox" [(ngModel)]="isValidAccept">
                                    <span class="checkmark">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.104" height="15.104"
                                             viewBox="0 0 15.104 15.104" stroke=" #50a7db">
                                            <path id="Caminho_200" data-name="Caminho 200"
                                                  d="M4918.568,1089.449h6.056v-11.3"
                                                  transform="translate(-2706.182 -4238.902) rotate(45)" fill="none"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round" stroke-width="2"/>
                                        </svg>
                                    </span>
                                </label>
                            </div>
                            <p class="kitchen_lbl col-auto px-1 mb-0">Aceito os termos mencionados acima.</p>
                        </div>
                        <p class="kitchen_text mb-4">
                            Seu consentimento para este fim é voluntário e você é livre para
                            retirá-lo a qualquer momento.</p>
                        <div class="row">
                            <div class="col-sm-6">
                                <a (click)="onLogout()" class="btn btn-block btn-outline-secondary">Cancelar</a>
                            </div>
                            <div class="col-sm-6">
                                <a (click)="acceptTerms()" class="btn btn-block btn-secondary">Salvar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-profile [inputUser]="this.user" (saveProfile)="handlerCurrent()"></app-profile>

<app-login></app-login>
