<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg text-left">Artigos</p>
        <div class="container">
            <h2 class="page_header_title">Artigos</h2>
        </div>
    </div>
</div>


<div class="hero-banner" *ngIf="flagMember === true">
    <img [src]="getImageURL()+ 'news/image/'+news.pathImage" class="hero-banner-thumb" alt="{{news.title}}"/>
</div>

<div class="container container-hero mt-5">
    <div class="row mb-5" style="row-gap: 20px">
        <div class="col-12 position-relative text-right">
            <a routerLink="/artigos" class="btn btn-outline-secondary back_button">
                <div class="d-flex align-items-center" style="gap: 20px">
                    <svg-icon src="assets/svg/arrow.svg"></svg-icon>
                    <p class="mb-0">Voltar</p>
                </div>
            </a>
        </div>
        <div class="col col-12" *ngIf="flagMember === true">
            <!--<div class="mb-4">
                <img [src]="getImageURL()+ 'news/image/'+news.pathImage" class="img-fluid w-100" alt="{{news.title}}"/>
            </div>-->
            <h2 class="text-uppercase text-primary">{{news.title}}</h2>
            <p class="main_news_text"><small>Publicada em {{news.createdAt | date: 'dd/MM/yyyy'}}</small></p>
            <div class="main_news_text" [innerHTML]="news.text"></div>
        </div>
        <div class="d-none d-lg-block col-2">&nbsp;</div>
    </div>
    <div class="d-flex align-items-center mb-5" style="gap: 10px" *ngIf="flagMember === true">
        <span class="news-detail_date">{{news.createdAt|date:'dd/MM/yyyy':'UTC-0'}}</span>
        <div class="divider">&nbsp;</div>
    </div>

    <div class="carousel" id="slick-news" *ngIf="flagMember === true">

        <div *ngFor="let n of newsPage.news">
            <div class="home-news_item">
                <div class="home-news_thumb">
                    <img src="{{this.getImageURL() + 'news/image/' +n.pathImage}}" alt="image">
                    <p>{{n.title}}</p>
                </div>
                <div class="px-3 home-news_content-scroll">
                    <p class="home-news_text" [innerHTML]="n.text"></p>
                    <div class="row justify-content-between align-items-center">
                        <div class="col-auto">
                            <p class="home-news_date mb-0">{{n.createdAt | date:'dd/MM/yyyy | HH:mm':'UTC-0'}}</p>
                        </div>
                        <div class="col-auto">
                            <a class="home-news_link" [routerLink]="'/articles-detalhe/' + n.id">Leia mais</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="container container-hero" *ngIf="flagMember === false">
    <div class="row">
        <div class="col-12 text-center py-5">
            <h3 class="mb-5">Seja um Membro</h3>
            <p class="mb-5">
                Faça seu <a href="javascript:void('');" (click)="showModal('modalLogin')" class="btn btn-sm btn-primary">Login</a> para entrar ou
                <a href="javascript:void('');" (click)="showModal('isProfile')" class="btn btn-sm btn-primary">Cadastre-se</a>
            </p>
        </div>
    </div>
</div>


