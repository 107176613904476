import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    ClickDownloadGQL, CreateDownloadAccessInput, Download,
    DownloadByCategoryQuery, DownloadPage, SearchDownloadGQL, SearchDownloadInput
} from '../../../generated/graphql';
import {QueryRef} from "apollo-angular";
import {environment} from "../../../environments/environment";
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-downloads',
    templateUrl: './downloads.component.html',
    styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent extends BaseComponent implements OnInit {
    labels: any = {
        previousLabel: '',
        nextLabel: '',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    section: string;
    downloadFilter: SearchDownloadInput = new SearchDownloadInput();
    categoryDownload: DownloadPage = new DownloadPage();
    downloadByCategoryQuery: QueryRef<DownloadByCategoryQuery>;
    downloads: Download[] = [];
    imageUrl: string;

    flagMember = false;

    constructor(public router: Router,
                public searchDownloadGQL: SearchDownloadGQL,
                public clickDownloadGQL: ClickDownloadGQL,
                public translate: TranslateService,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.downloadFilter.page = 1;
        this.userService.user.subscribe({
            next: data => {
                console.log(data);
                if (!this.isNullOrUndefined(data.id)) {
                    this.flagMember = this.userService.getStatusAuthenticated();
                    this.getDownloads();
                }
                this.imageUrl = environment.image_url;
                if (!this.flagMember) { return; }
                this.getDownloads();
            }, error: err => super.onError(err)
        });


    }

    getDownloads() {
        this.searchDownloadGQL.watch({input: this.downloadFilter}).valueChanges.subscribe(({data}) => {
            this.categoryDownload = data.searchDownload as DownloadPage;
            this.downloads = this.categoryDownload.downloads as Download[];
        }, error => super.onError(error));
    }

    handlerSvg(s: string) {
        switch (s) {
            case 'manuais':
            case 'cartazete':
                return 'photo';
            case 'video':
                return 'video';
            case 'regulamento':
                return 'regulament';
            default:
                return 'download';

        }
    }

    selectCategory(id: string) {
        if (this.section != id) {
            this.section = id;
            this.downloadByCategoryQuery.refetch({id: this.section}).then();
        }
    }

    downloadPath(path: string, id?: string) {
        const d: CreateDownloadAccessInput = {downloadId: id};
        this.clickDownloadGQL.mutate({input: d}).subscribe(({data}) => {
            if (!this.isNullOrUndefined(path)) {
                document.location.href = this.imageUrl + 'download/archive/' + path;
            }
        }, error => super.onError(error));

    }

    pageChanged(event) {
        this.downloadFilter.page = event;
        this.getDownloads();
    }
}
