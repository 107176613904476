<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">EXPLICAR</p>
        <div class="container">
            <h2 class="page_header_title">EXPLICAR</h2>
        </div>
    </div>
</div>
<div class="map">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/explain1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="title-main">EXPLICAR O PROCESSO</p>

                <p class="page_text text-white">
                    Expliquem o processo de cinco dias do Gamerllil, confirmem que os participantes estarão presentes durante todo esse período, compartilhem a agenda com a programação da semana e descrevam algumas regras básicas.</p>
                <img src="assets/images/explainline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Mapear
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
              Segunda-feira
            </span>
            <br>
            <span>
               <strong>início:</strong>
             10:15h
            </span>
            <br>
            <span>
               <strong>Duração:</strong>
            15min
            </span>
            <br>
            <span>
               <strong>Participante(s):</strong>
             Facilitador
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                Logo após o <strong [routerLink]="'/facilitador'">Facilitador</strong> ter apresentado o <strong [routerLink]="'/definidor'">Definidor</strong>, a <strong [routerLink]="'/equipe'">equipe</strong>, descrito o papel de cada membro e de ter feito uma dinâmica para <strong [routerLink]="'/quebragelo'">quebrar o gelo</strong>, a próxima atividade é colocar todos na mesma "página". Não presumam que só porque as pessoas compareceram, elas já sabem do que se trata. Expliquem o que é o Gamerllil, como ele ajudará a equipe a progredir e por que o processo funciona.
                <br>
                Mantenham a explicação curta, simples e clara. Um caminho que indicamos, é que vocês usem a apresentação de slides que disponibilizamos no nosso <strong [routerLink]="'/curso'">curso online</strong>. Mas se ainda não adquiriram o nosso curso, não se preocupem, seguem algumas dicas sobre como o Facilitador deve explicar o processo do Gamerllil.
            </p>
            <br>



            <p class="page_subtitle mb-4 "><strong>O QUE É?</strong></p>
            <br>
            <p>
                O Gamerllil é um método de cinco dias criado pelo designer <strong [routerLink]="'/jakeknapp'">Jake Knapp</strong> do Google para testar novas ideias e resolver <strong [routerLink]="'/desafios'">desafios</strong> críticos por meio da <strong [routerLink]="'/prototipar'">prototipagem</strong> e do <strong [routerLink]="'/testar'">teste</strong> de ideias com consumidores reais. O princípio por trás dele é simples: começar é muito mais  importante do que estar certo.
            </p>
            <br>
            <p class="page_subtitle mb-4 "><strong>PARA O QUE SERVE?</strong></p>
            <br>
            <p>
                O Gamerllil é ideal para resolver muitos dos problemas comuns às startups e corporações. De desafios de negócios de alto risco a lançamentos de novos produtos, serviços ou situações em que vocês precisem da adesão dos stakeholders.
            </p>
            <br>
            <p class="page_subtitle mb-4 "><strong>É SÓ PARA RESOLVER PROBLEMAS?</strong></p>
            <br>
            <p>
                Mas não é apenas sobre resolver problemas, e sim, sobre uma forma de mudar os antigos padrões do trabalho para uma maneira mais inteligente, eficaz e divertida com foco no que realmente importa.
            </p>
            <br>
            <p class="page_subtitle mb-4 "><strong>COMO SÃO OS CINCO DIAS?</strong></p>
            <br>
            <p>
                Na <strong [routerLink]="'/map'">segunda-feira</strong>, vocês <strong [routerLink]="'/mapa'">mapearão</strong> o problema e escolherão um <strong [routerLink]="'/alvo'">alvo</strong>. Na <strong [routerLink]="'/esbocar'">terça-feira</strong>, esboçarão soluções. Na <strong [routerLink]="'/decidir'">quarta-feira</strong> , tomarão decisões difíceis e transformarão as ideias em hipóteses. Na <strong [routerLink]="'/prototipar'">quinta-feira</strong>, construirão um protótipo realista. E, na <strong [routerLink]="'/jakeknapp'">sexta-feira</strong>, farão testes.
            </p>
            <br>
            <p class="page_subtitle mb-4 "><strong>QUAL O IMPACTO?</strong></p>
            <br>
            <p>
                Provavelmente não precisamos convencê-los de que o Gamerllil funciona e que ele pode ajudar startups ou corporações a economizarem muito (Tempo e Dinheiro). Mas, uma pergunta que recebemos muito (tanto de clientes quanto de alunos) é:
                <br>
                -Qual é o real impacto de utilizar o Gamerllil?
                <br>
                Temos várias (boas) respostas para essa pergunta:
                <br>
                -Fazendo as coisas muito mais rápido;
                <br>
                -Fazendo mais progressos em cinco dias do que normalmente seria feito em meses;
                <br>
                -Economizar muito dinheiro através da prototipagem para saber se a ideia dará certo ou não;
                <br>
                -Eliminar a necessidade de reuniões intermináveis que não chegam a lugar nenhum.
            </p>
            <br>
            <img src="assets/images/explain2.png" alt="">
            <br>
            <br>
            <p>
                Depois de explicar como o Gamerllil funciona, o Facilitador deve percorrer pelos nossos checklists de atividades diárias e descrevar brevemente cada uma delas. E por fim, deve alinhar as expectativas com a equipe.
                <br>
                <br>
                Alinhar expectativas é crucial para o sucesso de qualquer workshop, mas ainda mais o de Gamerllil. Porque o Gamerllil muda fundamentalmente a maneira como as equipes trabalham juntas e como o trabalho colaborativo acontece. Isso é o que o torna tão especial, mas também é o que o torna estranho às vezes.
                <br>
                <br>
                É importante que o Facilitador deixe claro para a equipe desde o início que o processo parecerá desconfortável as vezes, que eles irão sentir como se estivessem abrindo mão de boas ideias e indo rápido demais. Mas não se preocupem, isso é normal. Eles passarão por uma montanha-russa emocional de “isso é incrível!” para “isso nunca vai funcionar”. Tudo isso é uma parte inevitável do processo e não significa que o Gamerllil não funcione para uma equipe em particular.
                <br>
                <br>
                Por isso, é muito importante enfatizarem o processo do Gamerllil - se a equipe confiar, funcionará. É uma receita comprovada, testada, validada e divertida. O Facilitador deve explicar como nenhum Gamerllil é perfeito, mas que errar ajudará a construírem resultados excelentes. Simplesmente participando e seguindo as instruções, eles terão um Gamerllil bem-sucedido.
                <br>
                <br>
                Não se esqueçam de mencionar o compromisso de não ter distrações - ou seja, a equipe deve abandonar completamente seus telefones e laptops. Há apenas alguns poucos momento em que eles precisarão de um computador ou telefone, como por exemplo na atividade <strong [routerLink]="'/ideia'">Buscar ideia</strong> e <strong [routerLink]="'/apresentarideias'">Apresenta ideias</strong> na manhã de <strong [routerLink]="'/esbocar'">terça-feira</strong>, onde algumas pesquisas serão necessárias. Estas são algumas atividades que ao final o facilitador deve lembra a equipe dizendo algo menos irritante como:
                <br>
                <br>
                -Ok, agora vamos voltar ao modo de "produção focada". Vamos todos nos certificar de que nossos telefones estão desligados e entrar em ação!
                <br>
                <br>
                Certifiquem-se de aliviar a pressão garantindo à equipe que eles não precisam ser criativos. Os exercícios não requerem habilidades de nível artístico e ideias inovadoras. Na verdade, eles são projetados especificamente para promover o pensamento inovador sem que os participantes percebam.
                <br>
                <br>
                Dessa forma, o Facilitador deve garantir que o Gamerllil continue avançando sempre. Não é uma maratona, portanto, mesmo que o trabalho de algum membro da equipe não seja perfeito, seus esforços geralmente são bons o suficiente para levá-lo ao próximo passo. O Facilitador deve ser um bom "cronometrista". Deve sempre lembrar a equipe de quanto tempo eles têm e mantenha-os sempre.
                <br>
                <br>
                Ocasionalmente, vocês poderão encontrar alguns céticos ou criadores de caso no Gamerllil que parecem atrapalhar a programação. Lidar com <strong [routerLink]="'/equipe'">céticos e criadores</strong> de caso em um Gamerllil é um tópico à parte, mas uma boa regra é ouvi-los. Ignorar alguém com opiniões fortes vai torná-lo ainda mais determinado a transmitir o ponto de vista dele.
                <br>
                <br>
                Se os participantes estiverem um pouco desligados, vocês devem dar ouvidos a eles. Além disso, lembrem-os de que foram escolhidos seletivamente por seu imenso valor para o Gamerllil. Se as pessoas se sentirem ouvidas e valorizadas, geralmente vocês podem esclarecer qualquer mal-entendido.
                <br>
                <br>
                Vejam a seguir algumas ideias do <strong  [routerLink]="'/livro'">Livro Sprint</strong> e do <strong  [routerLink]="'/curso'">nosso Curso</strong> Online para manterem a energia e a motivação da equipe a todo vapor:
            </p>

            <br>
            <br>

            <div class="row">
                <div class="col-md-6 my-3">
                    <img src="assets/images/explainico1.png" alt="">
                    <p class="page_subtitle mb-4 "><strong>COMECEM E TERMINE COM UMA NOTA ALTA</strong></p>
                    <p>Sempre parabenizem, agradeçam e aplaudam a equipe pelo trabalho e participação.</p>
                </div>
                <div class="col-md-6 my-3">
                    <img src="assets/images/explainico2.png" alt="">
                    <p class="page_subtitle mb-4 "><strong>DECIDAM E SIGAM EM FRENTE</strong></p>
                    <p>Permitam que o Definidor quebre o bloqueio de decisão e siga em frente sempre.</p>
                </div>
                <div class="col-md-6 my-3">
                    <img src="assets/images/explainico3.png" alt="">
                    <p class="page_subtitle mb-4 "><strong>ESTEJAM SEMPRE ATENTOS</strong></p>
                    <p>Sintetizem as descobertas da equipe no quadro e em suas anotações. Continuem se perguntando “Como devemos capturar isso?”</p>
                </div>
                <div class="col-md-6 my-3">
                    <img src="assets/images/explainico4.png" alt="">
                    <p class="page_subtitle mb-4 "><strong>CUIDEM BEM DA EQUIPE</strong></p>
                    <p>Cuidem do ritmo de trabalho da equipe e tentem sempre mantê-los energizados. Façam pausas a cada 60 ou 90 minutos. Instruam-nos a consumirem uma comida leve no almoço e sirvam lanchinhos saudáveis para eles nos horários certos.</p>
                </div>
            </div>


            <br>
            <br>
            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE Gamerllil. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn" [routerLink]="'/quebragelo'">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/objetivo'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

