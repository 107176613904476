import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {AuthGuard} from './service/auth-guard.service';
import {UserComponent} from './views/user/user.component';
import {PrizeDetailComponent} from "./views/prize-detail/prize-detail.component";
import {PrizesComponent} from "./views/prizes/prizes.component";
import {CampaignComponent} from "./views/campaign/campaign.component";
import {NewsComponent} from "./views/news/news.component";
import {RecoveryComponent} from "./views/recovery/recovery.component";
import {FaqComponent} from "./views/faq/faq.component";
import {ContactComponent} from "./views/contact/contact.component";
import {DownloadsComponent} from "./views/downloads/downloads.component";
import {LogoutComponent} from "./views/logout/logout.component";
import {NewsDetailComponent} from "./views/news-detail/news-detail.component";
import {PrivacyPolicyComponent} from "./views/privacy-policy/privacy-policy.component";
import {LgpdComponent} from "./views/lgpd/lgpd.component";
import {WinnersComponent} from './views/winners/winners.component';
import {AboutComponent} from "./views/about/about.component";
import {PrototypeComponent} from "./views/prototype/prototype.component";
import {TestComponent} from "./views/test/test.component";
import {DecideComponent} from "./views/decide/decide.component";
import {SketchComponent} from "./views/sketch/sketch.component";
import {MapComponent} from "./views/map/map.component";
import {OrganizeComponent} from "./views/organize/organize.component";
import {JakeknappComponent} from "./views/jakeknapp/jakeknapp.component";
import {BookComponent} from "./views/book/book.component";
import {StoryComponent} from "./views/story/story.component";
import {ArticlesComponent} from "./views/articles/news.component";
import {CasesComponent} from "./views/cases/news.component";
import {TrademarkComponent} from "./views/trademark/trademark.component";
import {CourseComponent} from "./views/course/course.component";
import {ScheduleComponent} from "./views/schedule/schedule.component";
import {ExplainComponent} from "./views/explain/explain.component";
import {StoryboardComponent} from "./views/storyboard/storyboard.component";
import {TeamComponent} from "./views/team/team.component";
import {ArticlesDetailComponent} from "./views/articles-detail/news-detail.component";
import {ChallengeComponent} from "./views/challenge/challenge.component";
import {DefinerComponent} from "./views/definer/definer.component";
import {SpecialistComponent} from "./views/specialist/specialist.component";
import {FacilitatorComponent} from "./views/facilitator/facilitator.component";
import {RoomComponent} from "./views/room/room.component";
import {MaterialsComponent} from "./views/materials/materials.component";
import {ObjectiveComponent} from "./views/objective/objective.component";
import {QuestionsComponent} from "./views/questions/questions.component";
import {MapedComponent} from "./views/maped/maped.component";
import {IcebreakerComponent} from "./views/icebreaker/icebreaker.component";
import {CpComponent} from "./views/cp/cp.component";
import {OrganizeCpComponent} from "./views/organize-cp/organize-cp.component";
import {VoteCpComponent} from "./views/vote-cp/vote-cp.component";
import {SearchIdeasComponent} from "./views/search-ideas/search-ideas.component";
import {SketchSolutionsComponent} from "./views/sketch-solutions/sketch-solutions.component";
import {InterviewComponent} from "./views/interview/interview.component";
import {RecruitComponent} from "./views/recruit/recruit.component";
import {PresentIdeasComponent} from "./views/present-ideas/present-ideas.component";
import {DivideComponent} from "./views/divide/divide.component";
import {AnotateComponent} from "./views/anotate/anotate.component";
import {Crazy8Component} from "./views/crazy8/crazy8.component";
import {DefineGoalComponent} from "./views/define-goal/define-goal.component";
import {MuseumComponent} from "./views/museum/museum.component";
import {HeatComponent} from "./views/heat/heat.component";
import {CritiqueComponent} from "./views/critique/critique.component";
import {IntentionComponent} from "./views/intention/intention.component";
import {SupervoteComponent} from "./views/supervote/supervote.component";
import {BattleComponent} from "./views/battle/battle.component";
import {InventComponent} from "./views/invent/invent.component";
import {OpenComponent} from "./views/open/open.component";
import {HowPrototypeComponent} from "./views/how-prototype/how-prototype.component";
import {ToolComponent} from "./views/tool/tool.component";
import {ConquestComponent} from "./views/conquest/conquest.component";
import {StitchComponent} from "./views/stitch/stitch.component";
import {ReviewComponent} from "./views/review/review.component";
import {RoadmapComponent} from "./views/roadmap/roadmap.component";
import {ConfirmComponent} from "./views/confirm/confirm.component";
import {PrepareComponent} from "./views/prepare/prepare.component";
import {DataPanelComponent} from "./views/data-panel/data-panel.component";
import {WatchComponent} from "./views/watch/watch.component";
import {InsertComponent} from "./views/insert/insert.component";
import {IdentifyComponent} from "./views/identify/identify.component";
import {FinishComponent} from "./views/finish/finish.component";
import {CasesDetailComponent} from "./views/cases-detail/news-detail.component";
import {ContentDetailComponent} from "./views/content-detail/news-detail.component";

const routes: Routes = [
    /*{
        path: 'login', component: LoginComponent
    },*/
    {
        path: 'recovery/:id', component: RecoveryComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {path: 'regulamento', component: PrivacyPolicyComponent},
    {path: 'politica', component: LgpdComponent},
    {
        path: '', component: MainComponent,
        children: [
            {path: 'home', component: HomeComponent},
            {path: 'campanha', component: CampaignComponent},
            {path: 'usuarios', component: UserComponent},
            {path: 'downloads', component: DownloadsComponent},
            {path: 'anotar', component: AnotateComponent},
            {path: 'inserir', component: InsertComponent},
            {path: 'identificar', component: IdentifyComponent},
            {path: 'encerrar', component: FinishComponent},
            {path: 'premios', component: PrizesComponent},
            {path: 'confirmar', component: ConfirmComponent},
            {path: 'resgate', component: PrizeDetailComponent},
            {path: 'ganhadores', component: WinnersComponent},
            {path: 'costurar', component: StitchComponent},
            {path: 'conquistar', component: ConquestComponent},
            {path: 'roteiro', component: RoadmapComponent},
            {path: 'revisar', component: ReviewComponent},
            {path: 'noticias', component: NewsComponent},
            {path: 'dividir', component: DivideComponent},
            {path: 'objetivo', component: ObjectiveComponent},
            {path: 'mapa', component: MapedComponent},
            {path: 'perguntas', component: QuestionsComponent},
            {path: 'noticias-detalhe/:id', component: NewsDetailComponent},
            {path: 'artigos', component: ArticlesComponent},
            {path: 'artigos-detalhe/:id', component: ArticlesDetailComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'contato', component: ContactComponent},
            {path: 'quebragelo', component: IcebreakerComponent},
            {path: 'sobre', component: AboutComponent},
            {path: 'prototipar', component: PrototypeComponent},
            {path: 'testar', component: TestComponent},
            {path: 'recrutar', component: RecruitComponent},
            {path: 'decidir', component: DecideComponent},
            {path: 'organizar', component: OrganizeComponent},
            {path: 'esbocar', component: SketchComponent},
            {path: 'esbocarsolucoes', component: SketchSolutionsComponent},
            {path: 'comoprototipar', component: HowPrototypeComponent},
            {path: 'ferramenta', component: ToolComponent},
            {path: 'mapear', component: MapComponent},
            {path: 'jakeknapp', component: JakeknappComponent},
            {path: 'historia', component: StoryComponent},
            {path: 'livro', component: BookComponent},
            {path: 'buscarideias', component: SearchIdeasComponent},
            {path: 'apresentarideias', component: PresentIdeasComponent},
            {path: 'cases', component: CasesComponent},
            {path: 'cases-detalhe/:id', component: CasesDetailComponent},
            {path: 'conteudo/:id', component: ContentDetailComponent},
            {path: 'marca', component: TrademarkComponent},
            {path: 'crazy8', component: Crazy8Component},
            {path: 'preparar', component: PrepareComponent},
            {path: 'assistir', component: WatchComponent},
            {path: 'paineldados', component: DataPanelComponent},
            {path: 'curso', component: CourseComponent},
            {path: 'agenda', component: ScheduleComponent},
            {path: 'entrevistar', component: InterviewComponent},
            {path: 'artigos', component: ArticlesComponent},
            {path: 'storyboard', component: StoryboardComponent},
            {path: 'equipe', component: TeamComponent},
            {path: 'especialista', component: SpecialistComponent},
            {path: 'comopoderiamos', component: CpComponent},
            {path: 'organizarcp', component: OrganizeCpComponent},
            {path: 'votarcp', component: VoteCpComponent},
            {path: 'facilitador', component: FacilitatorComponent},
            {path: 'sala', component: RoomComponent},
            {path: 'materiais', component: MaterialsComponent},
            {path: 'explicar', component: ExplainComponent},
            {path: 'desafio', component: ChallengeComponent},
            {path: 'definidor', component: DefinerComponent},
            {path: 'critica', component: CritiqueComponent},
            {path: 'intencao', component: IntentionComponent},
            {path: 'inventar', component: InventComponent},
            {path: 'abertura', component: OpenComponent},
            {path: 'batalhar', component: BattleComponent},
            {path: 'supervoto', component: SupervoteComponent},
            {path: 'calor', component: HeatComponent},
            {path: 'mudeu', component: MuseumComponent},
            {path: 'definirobjetivo', component: DefineGoalComponent},
            {path: '', component: HomeComponent},
            {path: ':id', component: HomeComponent},
        ],
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: 'legacy',
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
